<template>
   <div>
      <div class="flex w-full bg-white ">
         <q-chat-sidebar :currentID="getTicketMessage" />
         <q-chat-conversation />
      </div>
   </div>
</template>

<script>
import {
   watch,
   onMounted,
   onUpdated,
   onBeforeMount,
   computed,
   reactive,
   ref,
} from '@vue/composition-api';
import exportFromJSON from 'export-from-json';
import useAppConfig from '@core/app-config/useAppConfig';
import URL from '@/views/pages/request';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { BRow, BCol, BModal, BButton, VBModal } from 'bootstrap-vue';
import QChatSidebar from '@/views/pages/chat/qChatSidebar.vue';
import QChatConversation from '@/views/pages/chat/qChatConversation.vue';
export default {
   props: [],

   components: {
      BRow,
      BCol,
      BModal,
      BButton,
      VBModal,
      QChatSidebar,
      QChatConversation,
   },

   setup(props, { root }) {
      const state = reactive({});

       const getTicketMessage = computed(() => {
         return root.$store.state.qParametre.ticketID;
      });

      return {
         getTicketMessage
      };
   },
};
</script>
<style scoped>
.test-modal .modal-dialog {
   max-width: 100%;
   margin: 0;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   height: 100vh;
   display: flex;
   position: fixed;
   z-index: 100000;
}
</style>
