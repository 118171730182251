export const qAtTime = (_at) => {
   let diff = { sec: 0, min: 0, hour: 0, day: 0 }; // Initialisation du retour
   let tmp = new Date() - new Date(_at);

   tmp = Math.floor(tmp / 1000); // Nombre de secondes entre les 2 dates
   diff.sec = tmp % 60; // Extraction du nombre de secondes

   tmp = Math.floor((tmp - diff.sec) / 60); // Nombre de minutes (partie entière)
   diff.min = tmp % 60; // Extraction du nombre de minutes

   tmp = Math.floor((tmp - diff.min) / 60); // Nombre d'heures (entières)
   diff.hour = tmp % 24; // Extraction du nombre d'heures

   tmp = Math.floor((tmp - diff.hour) / 24); // Nombre de jours restants
   diff.day = tmp;

   if (diff.day === 0 && diff.hour != 0) {
      return `il ya ${diff.hour} h ${diff.min} min`;
   } else if (diff.day != 0) {
      return `il ya ${diff.day} Jours`;
   } else if (diff.hour === 0) {
      return `il ya ${diff.min} min`;
   }
};
