<template>
   <!--  -->
   <div class="flex items-center gap-4 absolute bottom-0 bg-white w-full p-1">

      
          <div class="">
           <label for="idImage">
             <feather-icon
             
               icon="CameraIcon"
               class="mx-auto h-6 w-6 opacity-50 text-purple-900 hover:opacity-100"
            />
           </label>
            <input class="hidden h-[0px] w-[0px] " @change="addImage" multiple type="file" name="" id="idImage">
         </div>
      

      <!-- Write message -->
      <div
         class="flex justify-center items-center rounded-md  w-full border-[0.5px] py-[4px] px-[16px] bg-gray-50"
      >
         <input
         @keyup.enter="sendMessage"
            type="text"
            v-model="state.textMessage"
            class="h-[32px] flex w-full outline-none bg-transparent"
            placeholder="Entrez votre message..."
         />
        
      </div>

      <div class="">
            <feather-icon
               icon="MicIcon"
               class="mx-auto h-6 w-6 opacity-50 text-purple-900 hover:opacity-100"
            />
         </div>

      <!-- Botton to send message -->
      <div class="w-auto hidden">
         <div class=" bg-purple-900 py-[12px] px-2 rounded-r ">
            <button  class="flex justify-center items-center gap-1">
               <feather-icon
                  icon="SendIcon"
                  class="mx-auto text-white h- w-6"
               />
               <span class="text-white font-[900] ">Envoyer</span>
            </button>
         </div>
      </div>
   </div>
</template>

<script>
import {
   watch,
   onMounted,
   onUpdated,
   onBeforeMount,
   computed,
   reactive,
   ref,
} from '@vue/composition-api';
import exportFromJSON from 'export-from-json';
import useAppConfig from '@core/app-config/useAppConfig';
import URL from '@/views/pages/request';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { BRow, BCol, BModal, BButton, VBModal } from 'bootstrap-vue';

export default {
   props: ['allDataMessage'],

   components: {
      BRow,
      BCol,
      BModal,
      BButton,
      VBModal,
   },

   setup(props, { root }) {
      const state = reactive({
         textMessage: '',
         files: []
      });

       const getTicketMessage = computed(() => {
         return root.$store.state.qParametre.ticketID;
      });

      const addImage = (e) => {
         state.files = e.target.files
      }

      const sendMessage = async() => {
         let dataMessage = []
         dataMessage = props.allDataMessage
         let requestMessage = new FormData()
         requestMessage.append('ticket_id', getTicketMessage.value)
         requestMessage.append('description', state.textMessage)
          for (let i = 0; i < state.files.length; i++) {
                  const image = state.files[i];
                  requestMessage.append(`images[${i}]`, image);
               }

         try {
            const {data} = await axios.post(URL.TICKET_MESSAGE_SEND, requestMessage)
            if(data){
                dataMessage.push(data.message)
               state.textMessage = ''
            }
         } catch (error) {
            console.log(error)
         }

      }

      return {
         state,
         sendMessage,
         addImage
      };
   },
};
</script>
<style scoped></style>