<template>
   <!--  -->
   <div class="w-8/12 border-[0.5px] bg-gray-100 h-[70vh]   relative">
      <div class="object-cover absolute top-0 w-full opacity-20  h-full">
         <img
            src="/images/chat_bg.jpg"
            class="w-full h-full object-cover"
            alt=""
         />
      </div>

      <div class="relative flex flex-col p-2 h-[85%] overflow-auto gap-4">
         <span class="hidden"> {{ getTicketMessage }}</span>
         
            <q-chat-card :data="state.Message" :currentID="getTicketMessage" />
        
      </div>

      <q-chat-send-button v-if="getTicketMessage !== 0" :allDataMessage="state.Message" />

   </div>
</template>

<script>
import {
   watch,
   onMounted,
   onUpdated,
   onBeforeMount,
   computed,
   reactive,
   ref,
} from '@vue/composition-api';
import exportFromJSON from 'export-from-json';
import useAppConfig from '@core/app-config/useAppConfig';
import URL from '@/views/pages/request';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { BRow, BCol, BModal, BButton, VBModal } from 'bootstrap-vue';
import QChatCard from './__partiels/qChatCard.vue';
import QChatSendButton from './__partiels/qChatSendButton.vue';
export default {
   props: [],

   components: {
      BRow,
      BCol,
      BModal,
      BButton,
      VBModal,
      QChatCard,
      QChatSendButton,
   },
   created() {
     
   },

   setup(props, { root }) {
      const state = reactive({
         Message: []
      });

      const getTicketMessage = computed(() => {
         console.log(root.$store.state.qParametre.ticketID)
         gth(root.$store.state.qParametre.ticketID)
         return root.$store.state.qParametre.ticketID;
      });

      const gth = async(id) => {
         
          const { data } = await axios.get(URL.TICKET_MESSAGE + `/${id}`);
          
      if (data) {
         state.Message = []
         state.Message = data.echange
      }
      }



      return {
         state,
         getTicketMessage,
      };
   },
};
</script>
<style scoped>
.test-modal .modal-dialog {
   max-width: 100%;
   margin: 0;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   height: 100vh;
   display: flex;
   position: fixed;
   z-index: 100000;
}
</style>
