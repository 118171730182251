<template>
   <div class="w-4/12 rounded   bg-white h-[70vh] ">
      <!--  -->
      <div
         class="flex items-center p-1 m-auto w-full gap-2 border-b-[0.5px] h-16"
      >
         <!-- <div class="w-[40px] h-[40px] bg-gray-100 rounded-full ">
            <img src="" alt="" />
         </div> -->

         <div
            class="bg-white border-[0.5px] w-full rounded-full flex items-center px-[8px] gap-2"
         >
            <feather-icon
               icon="SearchIcon"
               class=" h-5 w-5 opacity-50 text-purple-900 hover:opacity-100"
            />
            <input
               type="text"
               class="outline-none bg-transparent py-[6px] "
               placeholder="Recherche..."
               @input="foundTicket"
            />
         </div>
      </div>

      <div class="flex flex-col h-[85%] overflow-auto ">
         <div class="px-2 pt-2 pb-[4px] ">
            <span class="text-purple-900 text-lg font-[900] ">
               Les tickets récents ({{ state.data.length }})
            </span>
         </div>

         <div v-for="(ticket, index) in state.data" :key="index">
            <div
               class="flex  hover:bg-purple-900/10 py-1 px-2 gap-2 cursor-pointer "
               :class="ticket.id === currentID ? 'bg-purple-900/20' : ''"
               @click="getTicketID(ticket.id)"
            >
               <!-- <div class="w-[40px] h-[40px] bg-gray-100 rounded-full ">
               <img src="" alt="" />
            </div> -->
               <div class="flex flex-col  w-full ">
                  <div class="flex justify-between items-center w-full">
                     <span class="text-[14px] font-[700] ">
                        {{ ticket.code }}
                     </span>
                     <span class="text-[10px] font-[700]">
                        {{ qAtTime(ticket.created_at) }}
                     </span>
                  </div>
                  <span class="text-[13px] font-[400] ">
                     {{ ticket.libelle }}
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {
   watch,
   onMounted,
   onUpdated,
   onBeforeMount,
   computed,
   reactive,
   ref,
} from '@vue/composition-api';
import exportFromJSON from 'export-from-json';
import useAppConfig from '@core/app-config/useAppConfig';
import URL from '@/views/pages/request';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { BRow, BCol, BModal, BButton, VBModal } from 'bootstrap-vue';
import { qAtTime } from '@/hooks/useAtTime';
export default {
   props: ['currentID'],

   components: {
      BRow,
      BCol,
      BModal,
      BButton,
      VBModal,
   },

   setup(props, { root }) {
      const state = reactive({
         data: [],
         searchTicket: ''
      });

      onMounted(() => {
         qGetAllTickets();
      });

      // Search a ticket
      const foundTicket = (e) => {
         state.data.filter((el) => {
            return  el.description 
         })
         e.target.value
         console.log(e.target.value)
      }

      // Get id of selected ticket
      const getTicketID = (_id) => {
         root.$store.commit('qParametre/TICKET_ID', _id, {
            root: true,
         });
      };

      // Get all tickets
      const qGetAllTickets = async () => {
         try {
            const { data } = await axios.get(URL.TICKET_LIST);
            if (data) {
               state.data = data.ticket.reverse();
            }
         } catch (error) {
            console.log(error);
         }
      };

      return {
         state,
         qAtTime,
         getTicketID,
         foundTicket
      };
   },
};
</script>
<style scoped>
.test-modal .modal-dialog {
   max-width: 100%;
   margin: 0;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   height: 100vh;
   display: flex;
   position: fixed;
   z-index: 100000;
}
</style>
