<template>
   <!--  -->
   <div class="flex flex-col gap-4">
      <div v-for="(message, index) in data" :key="index">
         <!-- {{message.created_user.id}} {{state.userID.entreprise.created_user}}
      {{state.userID['entreprise'].created_user === message.created_user.id }} -->
         <div
            class="flex gap-4 items-start "
            :class="
               state.userID['entreprise'].created_user !==
               message.created_user.id
                  ? 'justify-end'
                  : 'justify-start'
            "
         >
            <div
               class="bg-white p-[4px] shadow-xs w-[40px] h-[40px] rounded-full flex justify-center items-center"
               :class="
                  state.userID.entreprise.created_user !==
                  message.created_user.id
                     ? 'order-last'
                     : 'order-first'
               "
            >
               <feather-icon icon="UserIcon" class="h-16" />
            </div>
            <div class="max-w-[60%]">
               <div
                  class="inline-flex shadow-md rounded p-[12px] "
                  :class="
                     state.userID.entreprise.created_user !==
                     message.created_user.id
                        ? 'bg-gradient-to-r from-indigo-500 to-indigo-300 text-white'
                        : 'bg-white'
                  "
               >
                  <span class="">
                     {{ message.description }}
                  </span>
               </div>
            </div>
         </div>

         <div
            class="flex w-full"
            :class="
               state.userID['entreprise'].created_user !==
               message.created_user.id
                  ? 'justify-end'
                  : 'justify-start'
            "
         >
            <div
               class="max-w-[60%]  flex gap-4 flex-col mt-2"
               v-if="message.media.length !== 0"
            >
               <a
                  :href="media.original_url"
                  target="_blank"
                  v-for="(media, index) in message.media"
                  :key="index"
               >
                  <img
                     class="w-full  objet-fill"
                     :src="media.original_url"
                     alt=""
                  />
               </a>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import {
   watch,
   onMounted,
   onUpdated,
   onBeforeMount,
   computed,
   reactive,
   ref,
} from '@vue/composition-api';
import exportFromJSON from 'export-from-json';
import useAppConfig from '@core/app-config/useAppConfig';
import URL from '@/views/pages/request';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { BRow, BCol, BModal, BButton, VBModal } from 'bootstrap-vue';
import { qDecryptedJson } from '@/services/qeCrypt';
export default {
   components: {
      BRow,
      BCol,
      BModal,
      BButton,
      VBModal,
   },

   props: ['data', 'currentID'],

   setup(props, { root }) {
      const state = reactive({
         userID: null,
      });

      onMounted(() => {
         const __userID = localStorage.getItem('qhze_022');
         state.userID = qDecryptedJson(__userID);
      });

      return {
         state,
      };
   },
};
</script>
<style scoped></style>
