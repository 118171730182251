var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col gap-4"},_vm._l((_vm.data),function(message,index){return _c('div',{key:index},[_c('div',{staticClass:"flex gap-4 items-start ",class:_vm.state.userID['entreprise'].created_user !==
            message.created_user.id
               ? 'justify-end'
               : 'justify-start'},[_c('div',{staticClass:"bg-white p-[4px] shadow-xs w-[40px] h-[40px] rounded-full flex justify-center items-center",class:_vm.state.userID.entreprise.created_user !==
               message.created_user.id
                  ? 'order-last'
                  : 'order-first'},[_c('feather-icon',{staticClass:"h-16",attrs:{"icon":"UserIcon"}})],1),_c('div',{staticClass:"max-w-[60%]"},[_c('div',{staticClass:"inline-flex shadow-md rounded p-[12px] ",class:_vm.state.userID.entreprise.created_user !==
                  message.created_user.id
                     ? 'bg-gradient-to-r from-indigo-500 to-indigo-300 text-white'
                     : 'bg-white'},[_c('span',{},[_vm._v(" "+_vm._s(message.description)+" ")])])])]),_c('div',{staticClass:"flex w-full",class:_vm.state.userID['entreprise'].created_user !==
            message.created_user.id
               ? 'justify-end'
               : 'justify-start'},[(message.media.length !== 0)?_c('div',{staticClass:"max-w-[60%]  flex gap-4 flex-col mt-2"},_vm._l((message.media),function(media,index){return _c('a',{key:index,attrs:{"href":media.original_url,"target":"_blank"}},[_c('img',{staticClass:"w-full  objet-fill",attrs:{"src":media.original_url,"alt":""}})])}),0):_vm._e()])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }